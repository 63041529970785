import React from "react"
import * as styles from "./hero.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Button from "../../utils/Button/Button"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query getHeroImage {
      file(relativePath: { eq: "homePage/heroImage.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <main className={styles.hero}>
        <div className={styles.imgWrapper}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        </div>
        <div className={styles.text}>
          <h2>DOBRODOŠLI!</h2>
          <h1>Binary bioskop</h1>
          <p>Zato što učimo za 10</p>
          <div className={styles.btnContainer}>
            <Button type="yellow">
              {/* <Link to="/buyCourse">PRIJAVI SE</Link> */}
              <a href="/aplikacija/index.html">PRIJAVI SE</a>
            </Button>
          </div>
        </div>
      </main>
      <img src="/wave1.svg" alt="Waves" className="wave-global" />
    </>
  )
}

export default Hero
