import React from "react"
import * as styles from "./students.module.scss"
import Title from "./../../utils/Title/Title"
import Card from "./Card/Card"
// import { graphql, useStaticQuery } from "gatsby"

const Students = () => {
  // const data = useStaticQuery(graphql`
  //   query getStudentsImages {
  //     djak1: file(relativePath: { eq: "aboutPage/vlada.png" }) {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     djak2: file(relativePath: { eq: "aboutPage/vlada.png" }) {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     djak3: file(relativePath: { eq: "aboutPage/vlada.png" }) {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <div className={styles.students}>
      <Title
        type="pink"
        customStyle={{ textAlign: "center", marginBottom: "6rem" }}
      >
        Šta studenti kažu za nas
      </Title>
      <div className={styles.cards}>
        <Card
          text="Znanje koje sam stekla gledajući klipove u BB pomoglo mi je da položim nekoliko ispita. Dopada mi se to što mogu iznova da pogledam svaki video, što sama diktiram tempo učenja i ukoliko mi nešto nije jasno, dobijem dodatno objašnjenje od predavača. Zaista sve pohvale."
          // img={data.djak1.childImageSharp.fluid}
          name="Jovana Mijatović"
          school="FON"
        />
        <Card
          text="Fakultet sam završila uz pomoć Binary Bioskopa i imam samo lepe reči! Ništa se ne propušta, temeljno se obrađuje celo gradivo i nema potrebe za učenjem napamet jer je sve odlično objašnjeno. Svakom bih preporučila :)"
          // img={data.djak1.childImageSharp.fluid}
          name="Jovana Rakita"
          school="FON"
        />
        <Card
          text="Pripremni video klipovi iz Binary bioskopa su mi dosta značili i bili od koristi za postavljanje osnove kao i za nadogradnju postojećeg znanja u različitim oblastima, kako programiranja, tako i ostalim. Srdačno preporučujem Binary bioskop za bilo koga ko želi da oblikuje svoje mišljenje, razmišljanje, znanje i logiku u smeru programiranja."
          // img={data.djak1.childImageSharp.fluid}
          name="Milica Cekić"
          school="PMF"
        />
      </div>
    </div>
  )
}

export default Students
