import React from "react"
import * as styles from "./steps.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Card from "./../../utils/Card/Card"

const Steps = () => {
  const data = useStaticQuery(graphql`
    query getCardOne {
      cardOne: file(relativePath: { eq: "homePage/cardOne.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardTwo: file(relativePath: { eq: "homePage/cardTwo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardThree: file(relativePath: { eq: "homePage/cardThree.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.steps}>
      <Card
        title="Kreiraj svoj nalog"
        img={data.cardOne.childImageSharp.fluid}
      />
      <Card title="Odaberi pripremu" img={data.cardTwo.childImageSharp.fluid} />
      <Card
        title="Odgledaj pripremu"
        img={data.cardThree.childImageSharp.fluid}
      />
      <Card
        title="Položi ispit desetkom"
        img={data.cardThree.childImageSharp.fluid}
      />
    </div>
  )
}

export default Steps
