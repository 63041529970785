import React from "react"
import Layout from "../components/utils/Layout/Layout"
import Hero from "../components/homeComponents/Hero/Hero"
import { Seo } from "../components/seo"
import Steps from "../components/homeComponents/Steps/Steps"
import Students from "./../components/homeComponents/Students/Students"

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Steps />
      <Students />
    </Layout>
  )
}

export const Head = () => <Seo />
